<template>
  <div>
    <window-header></window-header>
    <section>
      <article style="width: 950px; height: 557px">
        <field name="fecha_desde" widget="input" type="date" label="Desde" required searchable width="100px"
          style="top: 9px; left: 10px" />
        <field name="fecha_hasta" widget="input" type="date" label="Hasta" required searchable width="100px"
          style="top: 9px; left: 120px" />
        <field name="representante_id" widget="m2o" label="Representante" labelProp="nombre" primary="codigo" searchable
          width="300px" style="top: 9px; left: 230px" :filter="[['activo', '=', 1]]" />

        <r-tabs ref="main-tabs" style="top: 55px; height: 500px" :buttons="['Mapa', 'Tabla']">
          <div class="tab">
            <div id="mapid" style="
                height: 100%;
                width: 100%;
                position: absolute;
                right: 0px;
                bottom: 0px; ;
              "></div>
          </div>
          <div class="tab">
            <hot-table ref="hotTableComponent" :settings="htSettings"
              style="position: absolute; left: 0px; bottom: 0px"></hot-table>
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<style>
.dialog-representantes .field[name="calculo_comisones"]>label {
  width: 100%;
}

.custom-pin {
  border-radius: 50%;
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--pin-color);
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
}

.custom-pin:before {
  content: "";
  width: 10px;
  height: 10px;
  background: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 50%;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, 0.5);
  -webkit-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, 0.5);
  box-shadow: inset -2px 2px 4px hsla(0, 0, 0, 0.5);
  z-index: 1;
}

.custom-pin:after {
  content: "";
  position: absolute;
  top: 65%;
  left: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid var(--pin-color);
}
</style>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

import Vue from "vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import LeafletSearch from "leaflet-search";
import "leaflet-search/dist/leaflet-search.min.css";
import "leaflet-search/dist/leaflet-search.mobile.min.css";
import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Localización de representantes",
      dbAdapter: "localizacion_representante",
      primary: "id",
      order: "fecha",
      orderDir: "DESC",
      defaultDataSearch: {
        fecha_desde: new Date().yyyymmdd(),
        fecha_hasta: new Date().yyyymmdd(),
      },
      additionalHtSettings: {
        height: "100%",
        width: "100%",
        minRows: 20,
        afterSelectionEnd: function (r, c, r2, c2) {
          var item = self.items[r2];
          if (!item.latitud || !item.longitud) return;
          self.map.panTo(new L.LatLng(item.latitud, item.longitud));
        },
      },
      map: null,
      lG: null,
      loadItemIfOne: false,
    };
  },
  methods: {
    initMap(center = [41.7031684, -4.9488961]) {
      var self = this;
      this.map = L.map("mapid", {
        zoomControl: false,
        fullscreenControl: true,
      }).setView(center, 15);
      this.lG = L.layerGroup().addTo(this.map);
      L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?layers=H",
        {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
          maxZoom: 18,
        }
      ).addTo(this.map);
      this.map.addControl(
        new L.Control.Search({
          url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
          //jsonpParam: "json_callback",
          propertyName: "display_name",
          propertyLoc: ["lat", "lon"],
          /*marker: L.circleMarker([0, 0], {
            radius: 30
          }),*/
          marker: false,
          autoCollapse: false,
          autoType: true,
          minLength: 2,
          textPlaceholder: self.$t("search"),
          textErr: "Sin resultados",
        }).on("search:locationfound", function () {
          self.loadItems();
        })
      );
    },
    createMarker(data) {
      var self = this;
      /*var icon = L.icon({
        iconUrl: require("./../assets/marker-icon.png"),
        shadowUrl: require("./../assets/marker-shadow.png"),
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41],
      });*/
      const icon = L.divIcon({
        className: "",
        iconSize: [20, 30],
        iconAnchor: [10, 30],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html:
          `<span class="custom-pin" style="--pin-color:hsl(` +
          data.hue +
          `,70%,60%);" />`,
      });
      var marker = L.marker([data.latitud, data.longitud], {
        icon: icon,
      }).addTo(this.lG);
      var popup = L.popup({
        minWidth: 200,
      });
      var instance = new Vue({
        data,
        i18n: self._i18n,
        render(h) {
          return h("div", [
            h("div", "Fecha: " + new Date(data.fecha).ddmmyyyyhhiiss()),
            h("div", "Rep.: " + data.representante_id),
          ]);
        },
      });
      /*var instance = new ComponentClass({
        propsData: { data: data },
        i18n: self._i18n,
      });*/
      instance.$mount();

      popup.setContent(instance.$el);
      marker.bindPopup(popup, { maxWidth: window.innerWidth - 30 });
      return marker;
    },
    getFilter: function () {
      var self = this;
      var res = [];
      if (self.formData.fecha_desde)
        res.push(["fecha", ">=", self.formData.fecha_desde]);
      if (self.formData.fecha_hasta)
        res.push(["fecha", "<=", self.formData.fecha_hasta + ' 23:59:59']);
      if (self.formData.representante_id)
        res.push([
          "representante_id",
          "=",
          self.formData.representante_id.codigo,
        ]);
      return res;
    },
  },
  mounted() {
    var self = this;
    navigator.geolocation.getCurrentPosition(
      function (pos) {
        let crd = pos.coords;
        self.initMap([crd.latitude, crd.longitude]);
      },
      function () {
        self.initMap();
      },
      { timeout: 10000, enableHighAccuracy: true, maximumAge: 60000 }
    );
    self.$on("load", function () {
      self.lG.clearLayers();
      let reps = [];
      self.items.forEach(function (item) {
        !reps.includes(item.representante_id) &&
          reps.push(item.representante_id);
      });
      self.items.forEach(function (item) {
        item.hue = parseInt(
          (reps.indexOf(item.representante_id) / reps.length) * 100
        );
        self.createMarker(item);
      });
    });
  },
};
</script>